import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ReturnStruct } from '../../structs/return.struct';
import { ListaUsuarioResponse } from '../../responses/usuario/lista-usuario.response';
import { UsuarioLookupResponse } from '../../responses/usuario/usuario-lookup.response';
import { UsuarioRequest } from '../../requests/usuario/usuario.request';
import { UsuarioResponse } from '../../responses/usuario/usuario.response';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(nomeUsuario:string, paginaAtual:number): Observable<ListaUsuarioResponse> {
    let uri:string = 'Usuario?nomeUsuario=' + nomeUsuario + '&paginaAtual=' + paginaAtual;
    
    return this.httpClient.get<ListaUsuarioResponse>(environment.urlApiUsuario + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public excluir(idUsuario:number): Observable<ReturnStruct> {    
    return this.httpClient.delete<ReturnStruct>(environment.urlApiUsuario + `Usuario/idUsuario/${idUsuario}`,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public lookup(): Observable<UsuarioLookupResponse> {    
    return this.httpClient.get<UsuarioLookupResponse>(environment.urlApiUsuario + `Usuario/Lookup`,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Incluir(usuarioRequest:UsuarioRequest): Observable<ReturnStruct> {    
    return this.httpClient.post<ReturnStruct>(environment.urlApiUsuario + `Usuario`, usuarioRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Alterar(idUsuario:number, usuarioRequest:UsuarioRequest): Observable<ReturnStruct> {    
    return this.httpClient.put<ReturnStruct>(environment.urlApiUsuario + `Usuario/idUsuario/${idUsuario}`, usuarioRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public refefinirSehha(idUsuario:number|null): Observable<ReturnStruct> {    
    return this.httpClient.put<ReturnStruct>(environment.urlApiUsuario + `Usuario/ResetarSenha/idUsuario/${idUsuario}`, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public ObterPorId(idUsuario:number): Observable<UsuarioResponse> {    
    return this.httpClient.get<UsuarioResponse>(environment.urlApiUsuario + `Usuario/idUsuario/${idUsuario}`, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  
}
 