import { Component } from '@angular/core';
import { MenuComponent } from "../../../shared/component/menu/menu.component";
import { ModuloEnum } from '../../../shared/enum/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enum/funcionalidade.enum';

@Component({
    selector: 'app-home',
    imports: [MenuComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.css'
})
export class HomeComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(){
    this.moduloEnum = ModuloEnum.home
    this.funcionalidadeEnum = FuncionalidadeEnum.master_usuario
  }

}
