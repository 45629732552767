
<app-menu [moduloEnum]="moduloEnum" [funcionalidadeEnum]="funcionalidadeEnum"></app-menu>

<div class="container">
    <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
        <div class="row">
            <div class="col-12">
                <h1 class="title-default">Perfis</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="nome">
                    <mat-error *ngIf="model.get('nome')?.invalid">Informe o nome</mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Descrição</mat-label>
                    <input matInput type="text" formControlName="descricao">
                </mat-form-field>
            </div>                  
        </div>
        <div class="row">
            <cdk-accordion class="profile-register-accordion">
                <ng-container *ngFor="let itemModulo of listaModuloPerfil; let index = index;">
                    <cdk-accordion-item
                *ngIf="itemModulo.moduloPermitido || this.isMaster"
                    #accordionItemModule="cdkAccordionItem"
                    class="profile-register-accordion-item"
                    role="button"
                    tabindex="0"
                    [attr.id]="'accordion-header-' + index"
                    [attr.aria-expanded]="accordionItemModule.expanded"
                    [attr.aria-controls]="'accordion-body-' + index">
                    <div [ngClass]="{'profile-register-accordion-item-header-expanded' : accordionItemModule.expanded, 'disabled-module' : !itemModulo.moduloPermitido}"
                    class="profile-register-accordion-item-header" (click)=" !itemModulo.moduloPermitido ? openModal(): accordionItemModule.toggle()">
                    {{ itemModulo.nomeModulo }}
                    <span *ngIf="itemModulo.moduloPermitido" [ngClass]="{'profile-register-accordion-item-description-expanded': accordionItemModule.expanded}"
                    class="profile-register-accordion-item-description">
                        Clique para  {{ accordionItemModule.expanded ? 'fechar' : 'abrir' }}
                    </span>
                    </div>
                    <div
                    class="profile-register-accordion-item-body"
                    role="region"
                    [style.display]="accordionItemModule.expanded ? '' : 'none'"
                    [attr.id]="'accordion-body-' + index"
                    [attr.aria-labelledby]="'accordion-header-' + index">
                    <div class="feature-content" *ngFor="let itemFuncionalidade of itemModulo.listaFuncionalidade; let indexFuncionalidade = index;"
                    [ngClass]="{'feature-border': indexFuncionalidade + 1 < itemModulo.listaFuncionalidade.length}">
                        <span class="feature-content-header">{{itemFuncionalidade.nome}}</span>
                        <div class="feature-content-roles">
                            <mat-checkbox class="role-checkbox" *ngFor="let itemPermissao of itemFuncionalidade.listaPermissao"
                            [checked]="itemPermissao.selecionado" (change)="itemPermissao.selecionado = !itemPermissao.selecionado">
                                {{itemPermissao.nome}}
                            </mat-checkbox>
                        </div>
                    </div>
                    </div>
                </cdk-accordion-item>
                </ng-container>
            </cdk-accordion>
        </div>
        <div class="row">
            <div class="col-md-3">
                <button type="submit" mat-flat-button class="btn-primary button-big">
                    @if (_isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                        Salvar
                    }
                </button>
            </div>
        </div>
    </form>
</div>


