import { Component, Input, OnInit } from '@angular/core';
import { ActionUserComponent } from "./action-user/action-user.component";
import { ActionNavComponent } from './action-nav/action-nav.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import { AuthService } from '../../services/API/usuario/auth.service';
import { ModuloEnum } from '../../enum/modulo.enum';
import { FuncionalidadesComponent } from './funcionalidades/funcionalidades.component';
import { FuncionalidadeEnum } from '../../enum/funcionalidade.enum';

@Component({
    selector: 'app-menu',
    imports: [
        ActionUserComponent,
        ActionNavComponent,
        FuncionalidadesComponent,
        CommonModule,
        RouterModule,
        MatBadgeModule,
    ],
    templateUrl: './menu.component.html',
    styleUrl: './menu.component.css'
})
export class MenuComponent implements OnInit {
  public showActionUser:boolean = false;
  public showActionNav:boolean = false;
  public showActionInvite:boolean = false;
  public showActionFuncionalidade:boolean = false;
  public areThereNotifications:boolean = false;

  @Input() moduloEnum:ModuloEnum;
  @Input() funcionalidadeEnum:FuncionalidadeEnum;
  public nomeModulo:string

  constructor(private authService:AuthService){

  }

  ngOnInit(): void {
    let userLoginResponse = this.authService.getUserInfos();
    this.nomeModulo = userLoginResponse.listaModulos.filter(c=> c.idModulo == this.moduloEnum)[0].nome
  }

  clickShowActionUser(action:any){
    if(action == null){
      this.showActionUser = !this.showActionUser;
      this.showActionNav = false;
      this.showActionInvite = false;
      this.showActionFuncionalidade = false
      return;
    }

    this.showActionUser = action;     
  }

  clickShowActionNav(action:any){
    if(action == null){
      this.showActionNav = !this.showActionNav;
      this.showActionUser = false;
      this.showActionInvite = false;
      this.showActionFuncionalidade = false
      return;
    }

    this.showActionNav = action;     
  } 

  clickShowFuncionalidade(action:any){
    if(action == null){
      this.showActionFuncionalidade = !this.showActionFuncionalidade;
      this.showActionUser = false;
      this.showActionNav = false;
      this.showActionInvite = false;
      return;
    }

    this.showActionFuncionalidade = action;     
  } 

  

  clickShowActionInvites(action:any){
    if(action == null){
      this.showActionInvite = !this.showActionInvite;
      return;
    }

    this.showActionInvite = action;     
  } 

  setAreThereNotifications(areThereNotifications: boolean){
    this.areThereNotifications = areThereNotifications;     
  } 
}
