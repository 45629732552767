@if(showActionFuncionalidade){
    <div class="container-item">
        <button mat-button (click)="clickContainerOut()" class="close-button">
            <span class="material-symbols-outlined">arrow_back</span> fechar
        </button>
        @if(_listaFuncionalidade){
            @for (funionalidade of _listaFuncionalidade; track funionalidade) {
                <ul>
                    <li>
                        <a routerLink="{{funionalidade.uri}}" [ngClass]="{'funcionalidade-active': funionalidade.idFuncionalidade == funcionalidadeEnum}">
                            <span class="material-symbols-outlined">{{funionalidade.googleIconClass}}</span>
                            {{funionalidade.nome}}
                        </a>
                    </li>
                </ul>
              }
        }
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
