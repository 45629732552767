import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { AlterarSenhaService } from '../../../shared/services/API/usuario/alterar-senha.service';
import { ResetarSenhaRequest } from '../../../shared/services/requests/usuario/resetar-senha.request';


@Component({
    selector: 'app-senha-alterar',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    templateUrl: './senha-alterar.component.html',
    styleUrl: './senha-alterar.component.css'
})
export class SenhaAlterarComponent implements OnInit{
  constructor( 
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private alterarSenhaService:AlterarSenhaService,
    private activatedRoute:ActivatedRoute ){

  }
  public model: FormGroup;
  public isLoading:boolean = false;
  public _token:string;
  public _verifyTokenReturnStruct:ReturnStruct;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      senha1: ['', [Validators.required]],
      senha2: ['', [Validators.required]]
    });

    this._token = this.activatedRoute.snapshot.paramMap.get('token') + "";
    this.verifyLink();
  }


  hideSenha1 = signal(true);
  clickEventSenha1(event: MouseEvent) {
    this.hideSenha1.set(!this.hideSenha1());
    event.stopPropagation();
  }

  hideSenha2 = signal(true);
  clickEventSenha2(event: MouseEvent) {
    this.hideSenha2.set(!this.hideSenha2());
    event.stopPropagation();
  }

  redirectLogin(){
    this.router.navigate(['/'])
  }

  post(){
    if (this.model.invalid  || this.isLoading)
      return;

    if(this.model.get('senha1')?.value != this.model.get('senha2')?.value){
      this.alertService.show("Erro", "As duas senhas precisam ser identicas", AlertType.error);
      return;
    }

    let resetarSenhaRequest:ResetarSenhaRequest = new ResetarSenhaRequest();
    resetarSenhaRequest.novaSenha = this.model.get('senha1')?.value;
    resetarSenhaRequest.token = this._token

    this.isLoading = true;

    this.alterarSenhaService.put(resetarSenhaRequest).subscribe({
      next: (response:ReturnStruct) => {
        this.isLoading = false;

        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }
        
        this.alertService.show("Sucesso", "Sua senha foi alterada" , AlertType.success);
        this.redirectLogin();
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  verifyLink(){

    this.alterarSenhaService.get(this._token).subscribe({
      next: (response:ReturnStruct) => {
        this.isLoading = false;

        this._verifyTokenReturnStruct = response;
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
