<app-menu [moduloEnum]="moduloEnum" [funcionalidadeEnum]="funcionalidadeEnum"></app-menu>

<div class="container">
    <div class="row">
        <div class="col-sm-6">
            <h1 class="title-default">Perfis</h1>
        </div>
        <div class="col-sm-6">
            <a type="button" mat-button class="btn-primary btn-add-right" routerLink="/master/perfil/cadastrar">
                <mat-icon>add</mat-icon>
                Novo Perfil
            </a>
        </div>
    </div> 
    <form class="form" [formGroup]="model"  (ngSubmit)="clickPesquisar()" class="search-area">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Buscar</mat-label>
                    <input matInput formControlName="pesquisa" type="text">
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <button type="submit" mat-button class="btn-secundary" style="width: 140px;">
                    Pesquisar
                </button>
            </div>
        </div>
    </form>

    @if(this._listaPerfilResponse != null && this._listaPerfilResponse.listaPerfil.length > 0){
        <table mat-table [dataSource]="_listaPerfilResponse.listaPerfil">
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="idPerfil">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                <td mat-cell *matCellDef="let element"> {{element.perfil.idPerfil}} </td>
            </ng-container>

            <ng-container matColumnDef="nomePerfil">
              <th mat-header-cell *matHeaderCellDef> Nome perfil </th>
              <td mat-cell *matCellDef="let element"> {{element.perfil.nome}} </td>
            </ng-container>

            <ng-container matColumnDef="descricao">
                <th mat-header-cell *matHeaderCellDef> Descrição </th>
                <td mat-cell *matCellDef="let element"> {{element.perfil.descricao}} </td>
            </ng-container>

            <ng-container matColumnDef="action-edit">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> 
                    <a mat-mini-fab aria-label="Editar" class="fab-action default" [routerLink]="['/master/perfil/cadastrar', {idPerfil: element.perfil.idPerfil}]">
                        <mat-icon>edit</mat-icon>
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="action-delete">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element"> 
                    <button mat-mini-fab aria-label="Excluir" class="fab-action default" (click)="abrirModalExcluir(element.perfil.idPerfil, element.perfil.nome)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="col-12 text-center ver-mais-pesquisa">
            <button mat-button class="btn-primary" [disabled]="_ultimaPagina" (click)="proximaPagina()">
                @if (_isLoading == true) {
                    <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                }
                @else{
                    <span class="material-symbols-outlined">keyboard_arrow_down</span>
                    Ver mais
                }
            </button>
        </div>
    }@else if(_isLoading == true){
        <table>
            @for (item of [1,2,3]; track item) {
                <tr>
                    <td>
                        <app-loading-box [height]="'23'" [width]="'110'" [marginTop]="'30'"></app-loading-box>
                    </td>
                    <td>
                        <app-loading-box [height]="'23'" [width]="'220'" [marginTop]="'30'" [marginLeft]="'50'"></app-loading-box>
                    </td>
                    <td>
                        <app-loading-box [height]="'23'" [width]="'320'" [marginTop]="'30'"  [marginLeft]="'50'"></app-loading-box>
                    </td>
                    <td>
                        <app-loading-box [height]="'23'" [width]="'40'" [marginTop]="'30'"  [marginLeft]="'100'"></app-loading-box>
                    </td>
                    <td>
                        <app-loading-box [height]="'23'" [width]="'40'" [marginTop]="'30'"  [marginLeft]="'20'"></app-loading-box>
                    </td>
                </tr>
            }
            
        </table>
    }
    @else if(this._listaPerfilResponse?.listaPerfil?.length == 0){
        <app-empty-list [title]="'Não foi encontrado nenhum perfil com o filtro informado'"></app-empty-list>
    }
    

    
</div>
