import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ListaUsuarioResponse } from '../../responses/usuario/lista-usuario.response';
import { UsuarioVerificarEmailResponse } from '../../responses/usuario/usuario-verificar-email.response';
import { ReturnStruct } from '../../structs/return.struct';

@Injectable({
  providedIn: 'root'
})
export class UsuarioVerificarEmailService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public get(email:string): Observable<UsuarioVerificarEmailResponse> {
    let uri:string = 'UsuarioVerificarEmail/email/' + email;
    
    return this.httpClient.get<UsuarioVerificarEmailResponse>(environment.urlApiUsuario + uri,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public post(email:string): Observable<ReturnStruct> {
    let uri:string = 'UsuarioVerificarEmail/email/' + email;
    
    return this.httpClient.post<ReturnStruct>(environment.urlApiUsuario + uri, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
 