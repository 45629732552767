import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ReturnStruct } from '../../structs/return.struct';
import { PrimeiroAcessoRequest } from '../../requests/usuario/primeiro-acesso.request';

@Injectable({
  providedIn: 'root'
})
export class PrimeiroAcessoService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public put(primeiroAcessoRequest:PrimeiroAcessoRequest): Observable<ReturnStruct> {
    return this.httpClient.put<ReturnStruct>(environment.urlApiUsuario + "PrimeiroAcesso", primeiroAcessoRequest)
    .pipe(
      catchError(this.handleError)
    )
  }
}
 