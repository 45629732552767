import { Usuario } from "../../models/usuario/usuario.model";

export class UsuarioRequest{
    idUsuario :number|null;
    email: string;  
    nomeUsuario: string;  
    telefone: string;  
    cpf: string;  
    master: boolean;    
    listaIdPerfil: number[];    
}